import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "",
        name: 'home',
        component: () => import("../views/Home.vue")
      },
      {
        path: "/role",
        name: "role",
        component: () => import("../views/sys/Role.vue")
      },
      {
        path: "/manager",
        name: "manager",
        component: () => import("../views/sys/Manager.vue")
      },
      {
        path: "/navigation",
        name: "navigation",
        component: () => import("../views/sys/Navigation.vue")
      },
      {
        path: "/siteconfig",
        name: "siteconfig",
        component: () => import("../views/sys/SiteConfig.vue")
      },
      {
        path: "/banner",
        name: "banner",
        component: () => import("../views/sys/Banner.vue")
      },
      {
        path: "/articleInfo",
        name: "articleInfo",
        component: () => import("../views/sys/ArticleInfo.vue")
      },
      {
        path: "/articleStatic/:id",
        name: "articleStatic",
        component: () => import("../views/sys/ArticleStatic.vue"),
      },
      {
        path: "/data",
        name: "data",
        component: () => import("../views/sys/Data.vue")
      },
      {
        path: "/category",
        name: "category",
        component: () => import("../views/sys/Category.vue")
      },
      {
        path: "/userlist",
        name: "userlist",
        component: () => import("../views/user/Userlist.vue")
      },
      {
        path: "/collect_year",
        name: "collect_year",
        component: () => import("../views/collect/Year.vue")
      },
      {
        path: "/shopGoods",
        name: "shopGoods",
        component: () => import("../views/mall/ShopGoods.vue")
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/mall/Order.vue")
      },
      {
        path: "/comment",
        name: "comment",
        component: () => import("../views/mall/Comment.vue")
      },
      {
        path: "/project",
        name: "project",
        component: () => import("../views/this/Project.vue")
      },
      {
        path: "/itchRORecord",
        name: "itchRORecord",
        component: () => import("../views/this/ItchRORecord.vue")
      },
      {
        path: "/fiveDRecord",
        name: "fiveDRecord",
        component: () => import("../views/this/FiveDRecord.vue")
      },
      {
        path: "/pedsQlRecord",
        name: "pedsQlRecord",
        component: () => import("../views/this/PedsQlRecord.vue")
      },
      {
        path: "/vasRecord",
        name: "vasRecord",
        component: () => import("../views/this/VasRecord.vue")
      },
       {
        path: "/medicine",
        name: "medicine",
        component: () => import("../views/this/Medicine.vue")
      },
      {
       path: "/doctor",
       name: "doctor",
       component: () => import("../views/user/Doctor.vue")
     },
  {
       path: "/dataSnalysis",
       name: "dataSnalysis",
       component: () => import("../views/dataSnalysis/DataSnalysis.vue")
     },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
